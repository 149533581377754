/* You can add global styles to this file, and also import other style files */
@import 'libs/ui-kit/src/lib/assets/styles/index.scss';

html[theme='light'] {
	@include toggleTheme(light);
}

html[theme='dark'] {
	@include toggleTheme(dark);
}
