@include fontFace("SourceSans3", "SourceSans3-ExtraLight", 200);
@include fontFace("SourceSans3", "SourceSans3-Light", 300);
@include fontFace("SourceSans3", "SourceSans3-Regular", 400);
@include fontFace("SourceSans3", "SourceSans3-Medium", 500);
@include fontFace("SourceSans3", "SourceSans3-SemiBold", 600);
@include fontFace("SourceSans3", "SourceSans3-Bold", 700);
@include fontFace("SourceSans3", "SourceSans3-ExtraBold", 800);
@include fontFace("SourceSans3", "SourceSans3-Black", 900);


@include fontFace("Roboto", "Roboto-Thin", 100);
@include fontFace("Roboto", "Roboto-Light", 300);
@include fontFace("Roboto", "Roboto-Regular", 400);
@include fontFace("Roboto", "Roboto-Medium", 500);
@include fontFace("Roboto", "Roboto-Bold", 700);
@include fontFace("Roboto", "Roboto-Black", 900);

@include fontFace("RobotoMono", "RobotoMono-Thin", 100);
@include fontFace("RobotoMono", "RobotoMono-ExtraLight", 200);
@include fontFace("RobotoMono", "RobotoMono-Light", 300);
@include fontFace("RobotoMono", "RobotoMono-Regular", 400);
@include fontFace("RobotoMono", "RobotoMono-Medium", 500);
@include fontFace("RobotoMono", "RobotoMono-Bold", 700);