@import 'variables';
@import 'mixins';
@import 'functions';

@import 'fonts';
@import 'core';
@import 'typography';
@import 'overlay';
@import 'shared';
@import 'themes';

//for storybook
html {
	@include toggleTheme(light);
}

body {
	overflow: auto;
}
