// SHADOWS
$theme-shadow-focus: 0px 0px 5px #468dff;
$theme-dropdowns-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.05);

// MEDIA
$xs: 320px;
$s: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

// ANIMATIONS
$anim-transition: all ease-in-out 300ms;
$anim-time: 300ms;

// SIZES
$container-lr-padding: 112px;
$container-tb-padding: 40px;
$header-heigh: 56px;
$header-sub-menu-height: 42px;
$table-cell-lr-padding: 8px;