@import './functions';

.controls-section {
	display: flex;
	flex-direction: column;
	gap: 18px;
	margin: 0 0 40px 0;

	&__head {
		font-weight: 700;
		font-size: 1.75rem;
		margin: 0 0 10px 0;
		text-transform: uppercase;
	}

	&__row {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 18px;
	}
}

.form-control-group {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.cross-button {
	cursor: pointer;
	padding: 20px;

	svg * {
		fill: get-color(icon-primary);
		transition: $anim-transition;
	}

	&:hover {
		svg * {
			fill: get-color(icon-secondary);
			transition: $anim-transition;
		}
	}
}

.page-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.page-content {
	width: 100%;

	@media (min-width: 1921px) {
		width: 1216px;
	}
}
