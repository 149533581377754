/* Add application styles & imports to this file! */
@import '@angular/cdk/overlay-prebuilt.css';
@import '../../assets/styles/functions';

// Overlay backdrop styling
.eui-overlay-backdrop {
	background-color: get-color(background-modal);

	&__disabled {
		opacity: 0 !important;
		pointer-events: none;
	}
}

// Overlay panel styling
.eui-overlay-panel {
	display: flex;
	flex-direction: column;
}
