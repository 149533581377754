@import './functions';

h1,
h2,
h3 {
	font-weight: 400;
	line-height: 1.5rem;
	color: get-color(text-primary);
	letter-spacing: 0.28px;
}

h1 {
	font-size: 1.75rem;
	line-height: 2.5rem;
}

h2 {
	font-size: 1.375rem;
}

h3 {
	font-size: 1rem;
}

a {
	text-decoration: none;
	color: get-color(text-accent);
	font-size: 0.75rem;
	letter-spacing: 0.28px;
}

p {
	letter-spacing: 0.28px;
}

.text-14 {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.text-12 {
	font-size: 0.75rem;
}

.semibold {
	font-weight: 600;
}

.light {
	font-weight: 300;
}

.underline {
	text-decoration: underline;
}

.roboto {
	font-family: 'Roboto';
}

.roboto-mono {
	font-family: 'RobotoMono';
}
